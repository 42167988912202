import React, { Component } from "react";
import { Row, Col, Carousel } from "react-bootstrap";

import { projects } from "../data";

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
    };
  }

  componentDidMount() {
    var index = window.location.pathname.split("/").pop();
    try {
      this.setState({ project: projects[index] });
    } catch (err) {
      this.props.history.push("/");
    }
  }

  render() {
    let project = this.state.project;
    if (!project) {
      return null;
    }

    let svgStyle = { width: 20, height: 20, margin: 2 };

    return (
      <Row style={{ marginTop: 20, marginBottom: 20 }}>
        <Col lg={5}>
          <h1>{project.name}</h1>
          <p>
            Technologies:{" "}
            {project.technologies.map((tech, key) => {
              return (
                <img
                  src={`/images/tech/${tech}.png`}
                  alt={tech}
                  style={svgStyle}
                />
              );
            })}
            <br />
            <div className="dateText">{project.year}</div>
          </p>
          {/* Gross, I know.. */}
          <span dangerouslySetInnerHTML={{ __html: project.description }} />
        </Col>
        <Col lg={7}>
          <Carousel>
            {project.imageUrls.map((url, key) => {
              return (
                <Carousel.Item>
                  <img
                    className="d-block"
                    src={url}
                    alt={key}
                    style={{
                      maxHeight: 600,
                      maxWidth: "100%",
                      margin: "0 auto",
                    }}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Col>
      </Row>
    );
  }
}

export default Project;
