import React, {Component} from 'react';
import {Card, Row, Col} from 'react-bootstrap';
import {withRouter} from "react-router";
import 'rpg-awesome/css/rpg-awesome.min.css'
import {projects} from '../data';

class Portfolio extends Component {

    renderProject(project, key) {
        return (
            <Col xs={12} sm={6} lg={4} key={key} onClick={() => this.props.history.push(`/portfolio/${key}`)}>
                <Card style={{margin: "15px 0px 15px 0px", cursor: "pointer"}}>
                <Card.Body>
                    <center>
                        <i className={project.icon}></i>
                        <Card.Title>{project.name}</Card.Title>
                    </center>
                </Card.Body>
                </Card>
            </Col>
        );
    }

    render () {
        return (
            <div>
                <h1>Projects</h1>
                <Row>
                {
                    projects.map((project, key) => {
                        return this.renderProject(project, key)
                    })
                }
                </Row>
            </div>
        );
    }
}

export default withRouter(Portfolio);