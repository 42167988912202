import React, {Component} from 'react';
import {Navbar, Nav } from 'react-bootstrap';
import {withRouter} from "react-router";

import {ReactComponent as Github} from './svgs/github.svg';
import {ReactComponent as LinkedIn} from './svgs/linkedin.svg';

class NavMenu extends Component {
    render () {
        return (
            <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg">
                <Navbar.Brand style={{cursor: "pointer"}} onClick={() => {this.props.history.push("/")}}>Jake Dorne</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse>
                    <Nav className="ml-auto">
                        <Nav.Link href="https://github.com/jakedorne"><Github height={32} width={32}/></Nav.Link>
                        <Nav.Link href="https://www.linkedin.com/in/jakedorne/"><LinkedIn height={32} width={32}/></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default withRouter(NavMenu);