export const projects = [
  {
    id: 0,
    name: "Climb Princess Climb",
    icon: "ra ra-castle-emblem ra-3x",
    technologies: ["csharpe", "unity"],
    description:
      '<p>Climb Princess Climb was an entry for the <a href="https://itch.io/jam/gdl-july-jam-2">GDL July Jam</a> and was made over a weekend by myself and two friends (a dev and artist).</p><p>The aim of the game is to escape a tower as fast as possible with as many princesses as possible. The game came in first place and is available <a href="https://itsnotagoodtime.itch.io/climb-princess-climb">here</a>.</p>',
    platform: "C# - Unity",
    imageUrls: [
      "/images/portfolio/cpc1.gif",
      "/images/portfolio/cpc2.gif",
      "/images/portfolio/cpc3.gif",
      "/images/portfolio/cpc4.gif",
    ],
    gitUrl: "",
    year: 2019,
  },
  {
    id: 1,
    name: "Dank: The Game",
    icon: "ra ra-archer ra-3x",
    technologies: ["csharpe", "unity", "blender"],
    description:
      "This was a project of mine over the summer to learn more about C# and game development but ended up being more of a lesson in 3D modelling and animation with Blender. My basic goal for it was to implement as many cool things as possible and will hopefully end up as a low poly MMORPG type game.",
    platform: "C# - Unity",
    imageUrls: [
      "/images/portfolio/dankgame1.gif",
      "/images/portfolio/dankgame2.gif",
      "/images/portfolio/dankgame3.gif",
      "/images/portfolio/dankgame4.gif",
    ],
    gitUrl: "www.github.com/jakedorne/Dank Game",
    year: 2017,
  },
  {
    id: 2,
    name: "Weather Simulator",
    icon: "ra ra-frost-emblem ra-3x",
    technologies: ["c++"],
    description:
      "This was a university project in a group of two to create something technically challenging in the field of Computer Graphics. I worked mostly on various shader effects and a particle system to create various weather effects based on input variables. These included effects such as fog and snow depending on humidity and temperature.",
    platform: "C++",
    imageUrls: ["/images/portfolio/comp308-1.png"],
    gitUrl: "www.github.com/jakedorne/COMP308-Final-Project",
    year: 2017,
  },
  {
    id: 3,
    name: "Email Extractor",
    icon: "ra ra-nodular ra-3x",
    technologies: ["python"],
    description:
      "This was my honours project with the objective being to automatically extract certain fields (Journal Names, Manuscript IDs, Dates, etc) from emails using machine learning. I experimented primarily with deep neural networks to achieve this, written in Python using Tensorflow.",
    platform: "Python",
    imageUrls: ["/images/portfolio/tensorflow-python.png"],
    gitUrl: "",
    year: 2017,
  },
  {
    id: 4,
    name: "GigFinda",
    icon: "ra ra-compass ra-3x",
    technologies: ["node"],
    description:
      "GigFinda is a web app made in 48 hours as part of the 2016 Summer of Tech Hackfest. GigFinda aggregates data from multiple APIs to give a visual representation of the events going on in a nearby area.",
    platform: "NodeJS",
    imageUrls: ["/images/portfolio/gigfinda.png"],
    gitUrl: "www.github.com/jakedorne/",
    year: 2016,
  },

  {
    id: 5,
    name: "Traveller",
    icon: "ra ra-ship-emblem ra-3x",
    technologies: ["ruby"],
    description:
      "Traveller is a Rails web application that emulates the 1977 Role Playing Game, Traveller.",
    platform: "Ruby on Rails",
    imageUrls: ["/images/portfolio/traveller.png"],
    gitUrl: "www.gitlab.com/jakedorne/",
    year: 2016,
  },
  {
    id: 6,
    name: "Color Jumper",
    icon: "ra ra-cubes ra-3x",
    technologies: ["android", "java"],
    description:
      "Color Jumper is a creatively named Android application where the goal is to jump as high as possible avoiding moving platforms with seizure inducing colour changes happening at all times.",
    platform: "Android / Java",
    imageUrls: [
      "/images/portfolio/color-jumper-1.gif",
      "/images/portfolio/color-jumper-2.gif",
    ],
    gitUrl: "www.github.com/jakedorne/Colour Jumper",
    year: 2016,
  },
  {
    id: 7,
    name: "Dodo Death Adventure",
    icon: "ra ra-roast-chicken ra-3x",
    technologies: ["csharpe", "unity"],
    description:
      "Super Dodo Death Adventure was a university project which mixed Lemmings with Tetris. The goal of the game was to create a path to safety before the Dodos stupidly walk to their deaths.",
    platform: "C# - Unity",
    imageUrls: ["/images/portfolio/superdododeathadventure.png"],
    gitUrl: "www.github.com/jakedorne/",
    year: 2016,
  },
  {
    id: 8,
    name: "Shank the World",
    icon: "ra ra-bone-knife ra-3x",
    technologies: ["java"],
    description:
      "Shank the World was a university group project and is a multiplayer game written in Java. Players spawn with a single key which can unlock any of the chests scattered around the map. The objective is to unlock a chest and to relentlessly shank the other players with it, steal their items, and continue shanking. I worked mostly on the renderer which included the base rendering, camera rotation/effects, and animation.",
    platform: "Java",
    imageUrls: [
      "/images/portfolio/shank-the-world.png",
      "/images/portfolio/shank-the-world2.jpg",
      "/images/portfolio/shank-the-world3.jpg",
    ],
    gitUrl: "www.github.com/jakedorne/",
    year: 2015,
  },
];

export const certifications = [
  {
    title: "AWS Certified Solutions Architect Associate",
    company: "AWS",
    year: 2019,
  },
  {
    title: "AWS Certified Developer Associate",
    company: "AWS",
    year: 2019,
  },
  {
    title: "AWS Certified SysOps Administrator Associate",
    company: "AWS",
    year: 2019,
  },
  {
    title: "Sumologic Pro User",
    company: "SumoLogic",
    year: 2019,
  },
  {
    title: "Sumologic Power User",
    company: "SumoLogic",
    year: 2019,
  },
];
