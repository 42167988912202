import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { certifications } from "../data";
import "react-vertical-timeline-component/style.min.css";

class Education extends Component {
  render() {
    return (
      <div>
        <h1>Education</h1>
        <VerticalTimeline layout={"1-column"}>
          <VerticalTimelineElement
            date="January 2014 - November 2017"
            icon={
              <img
                src="images/vuw.svg"
                alt={"VUW Logo"}
                style={{
                  width: 24,
                  height: 12,
                  marginTop: 14,
                  marginLeft: 8,
                  marginRight: 8,
                  marginBottom: 14,
                }}
              />
            }
            iconStyle={{ background: "#FFFFFF" }}
          >
            <h3 className="vertical-timeline-element-title">
              Victoria University of Wellington
            </h3>
            <h4 className="vertical-timeline-element-subtitle">BE(Hons)</h4>
            <div>
              <p>
                Bachelor of Engineering with second class honours majoring in
                Software Engineering.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="January 2009 - December 2013"
            icon={
              <img
                src="images/pc.jpg"
                alt={"Paraparaumu College Logo"}
                style={{ width: 24, height: 24, margin: 8 }}
              />
            }
            iconStyle={{ background: "#FFFFFF" }}
          >
            <h3 className="vertical-timeline-element-title">
              Paraparaumu College
            </h3>
          </VerticalTimelineElement>
        </VerticalTimeline>
        <h1>Certifications</h1>
        <VerticalTimeline layout={"1-column"}>
          {certifications.map((cert, key) => {
            return (
              <VerticalTimelineElement
                date={cert.year}
                icon={
                  <img
                    className="align-items-center"
                    src={`/images/tech/${cert.company}.png`}
                    alt={cert.title}
                    style={{
                      width: 24,
                      margin: 8,
                      position: "relative",
                      top: "50%",
                      transform: "translateY(-75%)",
                    }}
                  />
                }
                iconStyle={{
                  background: "#ffffff",
                }}
                key={key}
              >
                <h3 className="vertical-timeline-element-title">
                  {cert.company}
                </h3>
                <p>{cert.title}</p>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      </div>
    );
  }
}

export default Education;
