import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { ReactComponent as Xero } from "../svgs/xero.svg";
import { ReactComponent as Westpac } from "../svgs/westpac.svg";
import { ReactComponent as Kubes } from "../svgs/kubernetes.svg";
import { ReactComponent as Docker } from "../svgs/docker.svg";
import { ReactComponent as Dotnet } from "../svgs/dot-net.svg";
import { ReactComponent as Aws } from "../svgs/aws.svg";
import { ReactComponent as Jenkins } from "../svgs/jenkins.svg";
import { ReactComponent as Python } from "../svgs/python.svg";
import { ReactComponent as ReactLogo } from "../svgs/react.svg";
import { ReactComponent as Go } from "../svgs/go.svg";
import { ReactComponent as Kafka } from "../svgs/kafka.svg";

class Experience extends Component {
  render() {
    return (
      <div>
        <h1>Experience</h1>
        <VerticalTimeline layout={"1-column"}>
          <VerticalTimelineElement
            className={"vertical-timeline-element--work"}
            date="April 2022 - Present"
            icon={<Westpac />}
            iconStyle={{ background: "#fff", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">Westpac</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Platform Engineer
            </h4>
            <div>
              <p>
                <strong>
                  Development on Westpacs event streaming platform
                </strong>
              </p>
              <p>
                Technologies: <Kafka height={20} width={20} />{" "}
                <Kubes height={20} /> <Docker height={20} />{" "}
                <Jenkins height={20} />{" "}
              </p>
              <p>
                Development on Westpacs event streaming platform, allowing for
                improved data sharing across Westpac.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className={"vertical-timeline-element--work"}
            date="January 2021 - April 2022"
            icon={<Xero />}
            iconStyle={{ background: "#048fc2", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">Xero</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Ecosystem Engineer
            </h4>
            <div>
              <p>
                <strong>Development of APIs to enable Xero's ecosystem</strong>
              </p>
              <p>
                Technologies: <Dotnet height={20} /> <Aws height={20} />{" "}
              </p>
              <p>
                Development of main APIs enabling other developers to contribute
                to Xero's ecosystem.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className={"vertical-timeline-element--work"}
            date="January 2019 - December 2020"
            icon={<Xero />}
            iconStyle={{ background: "#048fc2", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">Xero</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Site Reliability Engineer
            </h4>
            <div>
              <p>
                <strong>Development of tools to enable reliable systems</strong>
              </p>
              <p>
                Technologies: <Kubes height={20} /> <Docker height={20} />{" "}
                <Dotnet height={20} /> <Aws height={20} />{" "}
                <Jenkins height={20} />
              </p>
              <p>
                Creation and deployment of in-house tools to streamline and give
                greater visibility of data to assist incident and problem
                management.
              </p>
            </div>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="November 2017 - December 2018"
            icon={
              <img
                src="images/cogs.png"
                alt={"Cogito Group Logo"}
                style={{ width: 24, height: 24, margin: 8 }}
              />
            }
            iconStyle={{ background: "#ffffff", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">Cogito Group</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Software Developer
            </h4>
            <p>
              <strong>Development and deployment of Jellyfish</strong>
            </p>
            <p>
              Technologies: <ReactLogo height={20} /> <Go height={20} />
            </p>
            <p>
              Jellyfish is a cyber-security control platform, tying together
              various public key infrastructure services provided by Cogito
              Group, allowing them to be managed in a centralised, easy to use
              application.
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            date="February 2017 - November 2017"
            icon={
              <img
                src="images/publons.png"
                alt={"Publons Logo"}
                style={{ width: 24, height: 24, margin: 8 }}
              />
            }
            iconStyle={{ background: "#336699", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">Publons</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Honours Project
            </h4>
            <div>
              <p>
                <strong>Automation of text extraction from emails</strong>
              </p>
              <p>
                Technologies: <Python height={20} />
              </p>
              <p>
                Worked on an honours project for Publons to research and develop
                a tool to extract valuable information from email receipts using
                deep neural networks with TensorFlow.
              </p>
            </div>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    );
  }
}

export default Experience;
