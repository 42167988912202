import React, {Component} from 'react';
import Container from "react-bootstrap/Container";

class Footer extends Component {
    render () {
        var style={width: "100%", height: 100, bottom: 0, position: "absolute", background: "#343a40", color: "white", padding: 20, textDecoration: "none"};
        
        return (
            <div style={style}>
                <Container>
                    <center>
                        <a href={"mailto:hello@jakedorne.com"} style={{color: 'white'}}>hello@jakedorne.com</a>
                    </center>
                </Container>
            </div>
        );
    }
}

export default Footer;