import React, {Component} from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import {BrowserRouter} from 'react-router-dom';
import {Route} from 'react-router';

import NavMenu from './NavMenu';
import Footer from './Footer';
// import About from './components/About';
import Experience from './components/Experience';
import Education from './components/Education';
import Portfolio from './components/Portfolio';
import Project from './components/Project';

import './App.css';

class App extends Component {

    render() {
        return (
            <div className="App" style={{height: "100%"}}>
                <BrowserRouter>
                    <div style={{minHeight: "100%", position: 'relative', backgroundColor: '#f5f5f5'}}>
                    <NavMenu/>
                    <Container style={{marginTop: 48, paddingBottom: 100}}>
                            <Route exact path={"/portfolio/:id"} component={Project}/>
                            <Route exact path={"/"} render={() => {
                                return (
                                    <div>
                                        {/*<About/>*/}
                                        <Portfolio/>
                                        <Row style={{marginTop: 48}}>
                                            <Col md={6}>
                                                <Experience/>
                                            </Col>
                                            <Col md={6}>
                                                <Education/>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            }}/>
                    </Container>
                    <Footer/>
                    </div>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
